import { ProxiesType } from "api/proxiesGroup";
import IDCol from "modules/Proxies/components/IDCol";
import ProxyActions from "modules/Proxies/components/ProxiesTableColumns/components/ProxyActions";
import ProxyStatus from "modules/Proxies/components/ProxiesTableColumns/components/ProxyStatus";
import WalletAmountColTitle from "modules/Proxies/components/WalletAmountColTitle";
import { t } from "pay-kit";

import styles from "./proxiesTableColumns.module.scss";

const idCol = {
	title: t("Index"),
	dataIndex: "id",
	key: "id",
	render: ({ id }: ProxiesType) => <IDCol id={id} dataTestId="id" />,
};

const uuidCol = {
	title: t("UUID"),
	dataIndex: "uuid",
	key: "uuid",
};

const countryCol = {
	title: t("Country"),
	dataIndex: "country_code",
	key: "country_code",
	dataTestId: "country_code",
};

const ipCol = {
	title: "IP",
	dataIndex: "ip",
	key: "ip",
	dataTestId: "ip",
};

const portCol = {
	title: t("Port"),
	dataIndex: "port",
	key: "port",
	dataTestId: "port",
};

const loginCol = {
	title: t("Login"),
	dataIndex: "username",
	key: "username",
	dataTestId: "username",
};

const walletAmountCol = (setWalletsAmountSideBarState) => ({
	title: <WalletAmountColTitle />,
	dataIndex: "wallets",
	key: "wallets",
	render: ({ wallets, ip }: ProxiesType) => {
		const walletsList = Object.keys(wallets).reduce(
			(accumulator: readonly string[], currentValue: string) => [...accumulator, ...wallets[currentValue]],
			[]
		);

		const handleClick = () => {
			if (walletsList.length > 0) {
				setWalletsAmountSideBarState((prev) => ({
					...prev,
					show: true,
					walletsData: {
						amount: walletsList.length,
						IDs: walletsList,
						ip,
						status: 0,
					},
				}));
			}
		};

		return (
			<span data-test-id="wallets" className={styles.walletsAmount} onClick={handleClick}>
				{walletsList.length}
			</span>
		);
	},
});

const typeCol = {
	title: t("Type"),
	dataIndex: "type",
	key: "type",
	dataTestId: "type",
};

const statusCol = {
	title: t("Status"),
	dataIndex: "status",
	key: "status",
	render: ({ status, uuid }: ProxiesType) => <ProxyStatus id={uuid} status={status} dataTestId="status" />,
};

const lastCheckedAtCol = {
	title: t("Date of checking"),
	dataIndex: "last_checked_at",
	key: "lastCheckedAt",
	// render: (obj: ProxiesType) => {
	// 	console.log(obj);
	// 	return <span>{obj.last_checked_at}</span>;
	// },
};

const actionsCol = {
	title: t("Actions"),
	dataIndex: "in_system",
	key: "in_system",
	render: ({ uuid }: ProxiesType) => <ProxyActions id={uuid} />,
};

export const proxiesTableColumns = (setWalletsAmountSideBarState: any) => [
	idCol,
	uuidCol,
	countryCol,
	ipCol,
	portCol,
	loginCol,
	walletAmountCol(setWalletsAmountSideBarState),
	typeCol,
	statusCol,
	lastCheckedAtCol,
	actionsCol,
];
