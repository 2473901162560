/* eslint-disable react/prop-types */
import { WalletTypesContext } from "contexts/WalletTypesContext";
import { Tooltip } from "pay-kit";
import { useContext } from "react";

import type { ProxyType } from "../../../utils/types";
import styles from "./WalletsList.module.scss";

type WalletsListPropsType = { readonly wallets: ProxyType["wallets"] };

const WalletsList: React.FC<WalletsListPropsType> = ({ wallets }) => {
	const walletTypesAPI = useContext(WalletTypesContext);

	if (!wallets || Array.isArray(wallets) || Object.keys(wallets).length === 0 || walletTypesAPI.isLoading) {
		return null;
	}

	const totalCount = Object.keys(wallets).reduce(
		(accumulator, currentValue) => accumulator + wallets[currentValue].length,
		0
	);

	return (
		<div className={styles.walletsList}>
			<div className={styles.totalCount}>
				<WalletIcon />
				{totalCount}:
			</div>
			{Object.keys(wallets).map((wt) => {
				const walletIds = wallets[wt];
				const psTotalCount = walletIds.length;
				const walletTypeName = walletTypesAPI.walletTypes.find((w) => w.code === wt)?.name || wt;

				return (
					<div className={styles.walletCount} key={wt}>
						<span>{walletTypeName}</span>
						{psTotalCount}
					</div>
				);
			})}
			<div className={styles.ellipsis} data-type="ellipsis">
				<Tooltip
					tip={
						<div className={styles.tooltipContent}>
							{Object.keys(wallets).map((wt) => (
								<div key={wt}>
									<span className={styles.psName}>{wt}</span>
									{wallets[wt].length}
								</div>
							))}
						</div>
					}
					preferredSide="bottom"
				>
					<span>&hellip;</span>
				</Tooltip>
			</div>
		</div>
	);
};

export default WalletsList;

const WalletIcon = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1.25 4.66675C1.25 3.14797 2.48122 1.91675 4 1.91675H12C13.5188 1.91675 14.75 3.14797 14.75 4.66675V5.79554C15.1519 6.0456 15.4194 6.49129 15.4194 6.99946V9.0003C15.4194 9.50847 15.1519 9.95416 14.75 10.2042V11.3334C14.75 12.8522 13.5188 14.0834 12 14.0834H4C2.48122 14.0834 1.25 12.8522 1.25 11.3334V4.66675ZM13.25 5.58252H12.3351C11 5.58252 9.91772 6.66481 9.91772 7.99988C9.91772 9.33495 11 10.4172 12.3351 10.4172H13.25V11.3334C13.25 12.0238 12.6904 12.5834 12 12.5834H4C3.30964 12.5834 2.75 12.0238 2.75 11.3334V4.66675C2.75 3.97639 3.30965 3.41675 4 3.41675H12C12.6904 3.41675 13.25 3.97639 13.25 4.66675V5.58252ZM11.4177 7.99988C11.4177 7.49324 11.8284 7.08252 12.3351 7.08252H13.9194V8.91724H12.3351C11.8284 8.91724 11.4177 8.50653 11.4177 7.99988Z"
			fill="#697077"
		/>
	</svg>
);
