import UpAndDownButtons from "components/UpAndDownButtons";
import { useProxyContext } from "modules/Proxies/contexts/ProxyContext";
import { useTranslation } from "pay-kit";

import styles from "./walletAmountColTitle.module.scss";

const WalletAmountColTitle = () => {
	const { setProxies } = useProxyContext();
	const { t } = useTranslation();

	const getWalletsList = (wallets: Record<string, string[]>) => Object.keys(wallets).reduce(
		(accumulator: readonly string[], currentValue: string) => [...accumulator, ...wallets[currentValue]],
		[]
	)

	const sortAscending = () => {
		setProxies((prevState) => prevState.slice().sort((a, b) => getWalletsList(a.wallets).length - getWalletsList(b.wallets).length));
	};

	const sortDescending = () => {
		setProxies((prevState) => prevState.slice().sort((a, b) => getWalletsList(b.wallets).length - getWalletsList(a.wallets).length));
	};

	return (
		<div className={styles.walletAmountColTitle}>
			<span>{t("Wallets' count")}</span>
			<UpAndDownButtons className={styles.UpAndDownButtons} onUp={sortAscending} onDown={sortDescending} />
		</div>
	);
};

export default WalletAmountColTitle;
