import { TableSelectedRowIds } from "@paykassma/pay-kit/lib/elements/Table/types";
import Filter from "components/Filter";
import { RefreshBlock } from "components/RefreshBlock/RefreshBlock";
import { Roles, useHasAccessHook } from "contexts/AuthContext";
import { useWalletTypesContext } from "contexts/WalletTypesContext";
import { proxiesTableColumns } from "modules/Proxies/components/ProxiesTableColumns";
import TableManipulateButtonsGroup from "modules/Proxies/components/TableManipulateButtonsGroup";
import AddingProxiesContextProvider, { useAddingProxiesContext } from "modules/Proxies/contexts/AddingProxiesContext";
import ProxyContextProvider, { useProxyContext } from "modules/Proxies/contexts/ProxyContext";
import { useTranslation } from "pay-kit";
import { Table } from "pay-kit";
import { useEffect, useState } from "react";
import useURLFilters from "utils/hooks/useURLFilters";

import styles from "./proxies.module.scss";

const Proxies = () => {
	const {
		proxies,
		walletsList,
		getWallets,
		isWalletListLoading,
		isGetCheckedLoading,
		isProxyLoading,
		isLoading,
		getProxies,
		setWalletsAmountSideBarState,
		countryList,
	} = useProxyContext();
	const { openAddProxyModal, setIsProxiesOpen } = useAddingProxiesContext();
	const [proxyIDs, setProxyIDs] = useState<TableSelectedRowIds>([]);
	const { t } = useTranslation();
	const [filter, setFilter] = useURLFilters();
	const hasAccessToFetchWallets = useHasAccessHook({ rule: Roles.WALLET_LIST });

	const { walletTypes, getWalletTypesList, isLoading: isLoadingWalletTypes } = useWalletTypesContext();

	useEffect(() => {
		getWalletTypesList();
	}, []);

	useEffect(() => {
		getProxies(filter);
	}, [filter]);

	const handleAddProxy = () => {
		openAddProxyModal(true);
		setIsProxiesOpen((proxiesOpen) => ({ ...proxiesOpen, isAddProxyOpen: true }));
	};

	const multipleActionsRenderer = (idList: ReadonlyArray<string>) => (
		<TableManipulateButtonsGroup idList={idList} setProxyIDs={setProxyIDs} />
	);
	const isTableLoading = () => isLoading || isGetCheckedLoading || isProxyLoading;

	return (
		<div>
			<div className={styles.addDebugButtons}>
				<RefreshBlock
					addBtnLabel={t("Add proxy")}
					onAddClick={handleAddProxy}
					onRefreshClick={() => getProxies(filter)}
				/>
			</div>
			<Filter
				fields={[
					{
						name: `type`,
						placeholder: t("Proxy type"),
						component: "select",
						options: [
							{ value: "", label: t("All") },
							{ value: "http", label: "http" },
							{ value: "https", label: "https" },
							{ value: "socks4", label: "socks4" },
							{ value: "socks5", label: "socks5" },
						],
						// className: styles.field,
					},
					{
						name: `status`,
						placeholder: t("Status"),
						component: "select",
						options: [
							{ value: "", label: t("All") },
							{ value: "1", label: t("Working") },
							{ value: "0", label: t("Not working") },
						],
						className: styles.field,
					},
					{
						name: `country_code`,
						placeholder: t("Country"),
						component: "select",
						options: [
							{ value: "", label: t("All") },
							...countryList.map((elem) => ({ label: elem.code, value: elem.code })),
						],
						className: styles.field,
					},
					{
						name: `wallet_number`,
						placeholder: t("Wallet number"),
						component: "select",
						options: [
							{ value: "", label: t("All") },

							...walletsList.map((wallet) => ({ label: wallet?.identifier, value: wallet?.identifier })),
						],
						// className: styles.field,
						hide: !hasAccessToFetchWallets,
						isLoading: isWalletListLoading,
					},
					{
						name: `wallet_type`,
						placeholder: t("Wallet type"),
						component: "select",
						options: [
							{ value: "", label: t("All") },
							...walletTypes.map((type) => ({ label: type.name, value: type.code })),
						],
						// className: styles.field,
						isLoading: isLoadingWalletTypes,
					},
					{
						name: `ip`,
						placeholder: t("IP"),
						component: "textInput",
						className: styles.field,
					},
					{
						name: `port`,
						placeholder: t("Port"),
						component: "textInput",
						className: styles.field,
					},
					{
						name: `username`,
						placeholder: t("Login"),
						component: "textInput",
						className: styles.field,
					},
				]}
				values={filter}
				onApply={setFilter}
			/>

			<Table
				className={styles.proxiesTable}
				rowKey="uuid"
				data={proxies}
				columns={proxiesTableColumns(setWalletsAmountSideBarState)}
				select={{
					multiselect: true,
					onRowsSelect: setProxyIDs,
					selectedIds: proxyIDs,
					renderMultipleActions: multipleActionsRenderer,
				}}
				isLoading={isTableLoading()}
			/>
		</div>
	);
};

export default () => (
	<ProxyContextProvider>
		<AddingProxiesContextProvider>
			<Proxies />
		</AddingProxiesContextProvider>
	</ProxyContextProvider>
);
