/* eslint-disable react/prop-types */
import { PlusIcon } from "components/Icons/PlusIcon/PlusIcon";
import { Button, Modal, t } from "pay-kit";
import { useState } from "react";

import type { ProxyType } from "../utils/types";
import styles from "./ProxiesField.module.scss";
import ProxyListItem from "./ProxyListItem";
import SelectProxy from "./SelectProxy";

type ProxiesFieldPropsType = {
	readonly proxiesList: readonly ProxyType[];
	readonly value: readonly { readonly uuid: string }[];
	readonly label: string;
	readonly onChange: (proxiesList: readonly { readonly uuid: string }[]) => void;
	readonly error?: string;
	readonly isRequired?: boolean;
};

const ProxiesField: React.FC<ProxiesFieldPropsType> = ({
	value = [],
	proxiesList = [],
	label,
	onChange,
	error,
	isRequired,
}) => {
	const [selectModalIsOpen, setSelectModalIsOpen] = useState<boolean>(false);
	const onProxyDelete = (id: string) => onChange(value.filter((p) => p.uuid !== id));
	const selectedProxiesUUIDs = value.map((p) => p.uuid);
	const selectedProxies = selectedProxiesUUIDs.map((uuid) => proxiesList.find((p) => p.uuid === uuid));
	const notSelectedProxies = proxiesList.filter(({ uuid }) => !selectedProxiesUUIDs.includes(uuid));

	return (
		<div className={styles.proxies}>
			<div className={styles.header}>
				<div className={styles.heading}>
					{label}
					{isRequired && <span className={styles.asterisk}>*</span>}
				</div>
				<Button data-test-id="add-proxy" onClick={() => setSelectModalIsOpen(true)} variant="text-primary">
					<PlusIcon className={styles.plusIcon} />
					{t("Add")}
				</Button>
			</div>
			<div className={styles.rows}>
				{selectedProxies.map(
					(proxy) => proxy && <ProxyListItem data={proxy} key={proxy.uuid} onDelete={() => onProxyDelete(proxy.uuid)} />
				)}
			</div>
			{error && <div className={styles.error}>{error}</div>}
			{selectModalIsOpen && (
				<Modal
					title={t("Add proxy")}
					onClose={() => setSelectModalIsOpen(false)}
					isOpen={selectModalIsOpen}
					className={styles.selectProxyModal}
				>
					{selectModalIsOpen && (
						<SelectProxy
							proxies={notSelectedProxies}
							onSelect={(uuids: readonly string[]) => {
								onChange([...value, ...uuids.map((uuid) => ({ uuid }))]);
								setSelectModalIsOpen(false);
							}}
							onCancel={() => setSelectModalIsOpen(false)}
						/>
					)}
				</Modal>
			)}
		</div>
	);
};

export default ProxiesField;
